@import 'locomotive';

* {
  box-sizing: border-box;
  -webkit-touch-callout: none;
}

html, body {
  margin: 0;
  padding: 0;
  background: black;
  color: white;
  font-size: 20px;
  font-family: arial, sans-serif;
  line-height: 1.7;
}

.svg-sprite {
  display: none;
}

a {
  color: white;
  text-decoration: none;
}

nav {
  position: fixed;
  z-index: 3;
  padding: 20px;
  width: 100%;
  mix-blend-mode: difference;
  background: #000;
  pointer-events: none;
  display: flex;
  align-items: center;

  #logo {
    svg {
      fill: white;
      width: 130px;
      height: 40px;
      margin: 0 20px 0 0;
      transform: translateY(8px);
    }
  }

  a {
    pointer-events: all;
    color: black;
    background: white;
    font-size: 12px;
    display: inline-block;
    padding: 2px 10px;
    text-decoration: none;
    border-radius: 3px;
    margin: 0 4px;
  }
}

main {
  position: relative;
  z-index: 2;
}

canvas {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

h1 {
}

.gl__item {
  height: 200vh;
  width: 100%;
}

div.site {
  padding-left: 8vw;
  padding-right: 8vw;
}

#meter {
  position: fixed;
  top: 0;
  height: 5px;
  background: #fff;
  z-index: 10;
  width: 100%;
  transform-origin: 0 0;
  transform: scaleX(0);
}


.scroll__element {
  position: relative;
  z-index: 1;
}

.scroll__item {
  position: absolute;
  z-index: 2;
  top: 200px;
  left: 100px;
  width: 200px;
  height: 200px;
  background: red;
}